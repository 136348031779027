import GATSBY_COMPILED_MDX from "/home/runner/work/jobs-vendoor/jobs-vendoor/src/blog/como-preparar-seu-curriculo/index.mdx";
import React from 'react';
import {Link} from 'gatsby';
import {MDXProvider} from '@mdx-js/react';
import {Layout} from '../../components/layout/layout';
import * as classes from '../../styles/templates/blog-post.module.scss';
import VendoorBanner from '../../components/vendoor-banner/vendoor-banner';
import VacancyFilterContainer from '../../components/vacancy-filter-container/vacancy-filter-container';
import BlogPost from '../../components/blog-post/blog-post';
import {BlogCardList} from '../../components/blog/blog-card-list/blog-card-list';
import {MediumBlogCard} from '../../components/blog/medium-blog-card/medium-blog-card';
import {SEO} from '../../components/seo/seo';
const shortcodes = {
  Link
};
const BlogPostPage = _ref => {
  let {data, children} = _ref;
  const {title, slug} = data.mdx.frontmatter;
  const relatedPosts = data.related.nodes;
  return React.createElement("main", null, React.createElement(Layout, {
    hideFilter: true,
    breadcrumbItems: [{
      title: 'Blog',
      href: `/blog/`
    }, {
      title: title,
      href: `/blog/${slug}/`
    }]
  }, React.createElement("div", {
    className: classes.grid
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, React.createElement("div", {
    className: classes.post
  }, React.createElement(BlogPost, {
    children: children,
    data: data.mdx.frontmatter
  }))), React.createElement("div", {
    className: classes.banner
  }, React.createElement(VendoorBanner, null)), React.createElement("div", {
    className: classes.filter
  }, React.createElement("h2", {
    className: "text-black text-[16px] font-bold mb-5 text-center md:text-left"
  }, "Busque vagas"), React.createElement(VacancyFilterContainer, {
    selectsInColumn: true,
    fullWidth: true
  }))), React.createElement("div", {
    className: classes.related
  }, React.createElement(BlogCardList, {
    title: 'Relacionados'
  }, relatedPosts.map(post => React.createElement(MediumBlogCard, {
    title: post.frontmatter.title,
    coverImage: post.frontmatter.coverImage,
    categories: post.frontmatter.categories,
    description: post.excerpt,
    date: post.frontmatter.date,
    slug: post.frontmatter.slug,
    key: post.frontmatter.slug
  }))), React.createElement("a", {
    href: `https://vendoorvagas.com.br`,
    className: "bg-[#E1F317] rounded-full shadow-[0_0_25px_0px_rgba(113,128,150,0.5)] py-2 px-4 h-[40px] font-semibold text-xs flex justify-center items-center w-fit mx-auto"
  }, "Voltar para p\xE1gina inicial"))));
};
const query = "3832207123";
export const Head = _ref2 => {
  let {data} = _ref2;
  const getBlogPost = blogPost => {
    const imageSources = blogPost.images.map(image => image.frontmatter.coverImage.childImageSharp.gatsbyImageData.images.fallback.src);
    return {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: blogPost.title,
      image: imageSources,
      datePublished: new Date(blogPost.date),
      keywords: blogPost.keywords,
      author: [{
        '@type': 'Organization',
        name: 'Vendoor',
        url: 'https://vendoorvagas.com.br'
      }]
    };
  };
  return React.createElement(SEO, {
    pathname: `/blog/${data.mdx.frontmatter.slug}/`,
    schema: getBlogPost({
      title: data.mdx.frontmatter.title,
      date: data.mdx.frontmatter.date,
      images: [data.image_1x1, data.image_4x3, data.image_16x9],
      keywords: data.mdx.frontmatter.tags
    }),
    title: data.mdx.frontmatter.title,
    image: data.image_16x9.frontmatter.coverImage.childImageSharp.gatsbyImageData.images.fallback.src,
    description: data.mdx.body
  });
};
BlogPostPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
